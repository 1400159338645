.chapters {
  flex: 0.1;
  width: 100%;
  height: 100%;

  border-bottom: 1px solid lightgray;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.chapters > form {
  display:flex;
  width: 80%;
  justify-content: space-evenly;
}

.chapter__label {
  white-space: nowrap;
  line-height: 1.2rem;
}

.chapter__checkbox {
  margin-right: 5px;
}

.chapter__softwarearchitecture {
  border-bottom: 5px solid var(--softwarearchitecture-color) !important; 
}

.chapter__nlp {
  border-bottom: 5px solid var(--nlp-color) !important;
}

.chapter__mlinproduction {
  border-bottom: 5px solid var(--mlinproduction-color) !important;
}

.chapter__computervision {
  border-bottom: 5px solid var(--computervision-color) !important;
}

.chapter__structureddata {
  border-bottom: 5px solid var(--structureddata-color) !important;
}

.chapter__q {
  border-bottom: 5px solid var(--q-color) !important;
}

