.header {
  height: 10vh;
  display: flex;
  align-items: center;
  background-color: black;
  justify-content: space-between;
  padding: 0 2rem;
}

.header__left {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  margin-top: 0;
}

.header__logo {
  object-fit: contain;
  height: 60px;
  margin-right: 10px;
}

.header__title {
  font-weight: 400;
  color: white;
}

.header__title__sep {
  font-weight: 400;
  color: orange;
}

.header__signOut {
  color: white;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
