.projects {
  flex: 0.8;
  width: 100%;
  max-height: 80vh;
  overflow-y: scroll;
  outline: none;
  border: 2px solid lightgray;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.project__line {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selected {
  background: orange;
  white-space: normal;
}
