.app {
  height: 100vh;

  display: flex;
  flex-direction: column;
}

.app__body {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.app__bodyLeft {
  flex: 0.2;
  height: 85%;
  width: 100%;
  max-width: 20vw;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.app__bodyRight {
  flex: 0.7 ;
  height: 85%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.app__footer {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__footer__centerMessage {
  flex: 1;
  
  text-align: center;
  align-items: center;
}
