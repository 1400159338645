.search { 
  flex: 0.1;
  width: 100%;
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.search__bar {
  width: 100%;
  height: 75%;
  font-size: 100%; 
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  outline: none;
}