.components {
  flex: 0.8;
  width: 100%;
  max-height: 80vh;
  
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: top;
  justify-content: space-between;
}

.component__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.component__box {
  margin: 0.3rem 0.2rem 0 0;
  border-radius: 15px;
  padding: 1px 4px;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
}

.component__hidden {
  display: none !important;
}

.component__checkbox {
  margin-right: 5px;
}

.component__softwarearchitecture {
  background: var(--softwarearchitecture-color) !important; 
}

.component__nlp {
  background: var(--nlp-color) !important; 
}

.component__mlinproduction {
  background: var(--mlinproduction-color) !important; 
}

.component__computervision {
  background: var(--computervision-color) !important; 
}

.component__structureddata {
  background:var(--structureddata-color) !important; 
}

.component__q {
  background: var(--q-color) !important; 
}